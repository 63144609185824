import AxewImg from "../../../../src/assets/box-sprites/axew.png";
import BellsproutImg from "../../../../src/assets/box-sprites/bellsprout.png";
import ChikoritaImg from "../../../../src/assets/box-sprites/chikorita.png";
import ConkeldurrImg from "../../../../src/assets/box-sprites/conkeldurr.png";
import CyndaquilImg from "../../../../src/assets/box-sprites/cyndaquil.png";
import DeinoImg from "../../../../src/assets/box-sprites/deino.png";
import DuosionImg from "../../../../src/assets/box-sprites/duosion.png";
import EelektrossImg from "../../../../src/assets/box-sprites/eelektross.png";
import EmboarImg from "../../../../src/assets/box-sprites/emboar.png";
import GothitaImg from "../../../../src/assets/box-sprites/gothita.png";
import HonedgeImg from "../../../../src/assets/box-sprites/honedge.png";
import HorseaImg from "../../../../src/assets/box-sprites/horsea.png";
import KlinkImg from "../../../../src/assets/box-sprites/klink.png";
import LeavannyImg from "../../../../src/assets/box-sprites/leavanny.png";
import LitwickImg from "../../../../src/assets/box-sprites/litwick.png";
import LuxioImg from "../../../../src/assets/box-sprites/luxio.png";
import MarillImg from "../../../../src/assets/box-sprites/marill.png";
import RhyornImg from "../../../../src/assets/box-sprites/rhyorn.png";
import RoseliaImg from "../../../../src/assets/box-sprites/roselia.png";
import SamurottImg from "../../../../src/assets/box-sprites/samurott.png";
import SerperiorImg from "../../../../src/assets/box-sprites/serperior.png";
import SphealImg from "../../../../src/assets/box-sprites/spheal.png";
import StaraviaImg from "../../../../src/assets/box-sprites/staravia.png";
import SwinubImg from "../../../../src/assets/box-sprites/swinub.png";
import TogekissImg from "../../../../src/assets/box-sprites/togekiss.png";
import TotodileImg from "../../../../src/assets/box-sprites/totodile.png";
import VenipedeImg from "../../../../src/assets/box-sprites/venipede.png";
import VigorothImg from "../../../../src/assets/box-sprites/vigoroth.png";
import * as React from 'react';
export default {
  AxewImg,
  BellsproutImg,
  ChikoritaImg,
  ConkeldurrImg,
  CyndaquilImg,
  DeinoImg,
  DuosionImg,
  EelektrossImg,
  EmboarImg,
  GothitaImg,
  HonedgeImg,
  HorseaImg,
  KlinkImg,
  LeavannyImg,
  LitwickImg,
  LuxioImg,
  MarillImg,
  RhyornImg,
  RoseliaImg,
  SamurottImg,
  SerperiorImg,
  SphealImg,
  StaraviaImg,
  SwinubImg,
  TogekissImg,
  TotodileImg,
  VenipedeImg,
  VigorothImg,
  React
};