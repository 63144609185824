import AggronImg from "../../../../src/assets/box-sprites/aggron.png";
import AxewImg from "../../../../src/assets/box-sprites/axew.png";
import BeedrillImg from "../../../../src/assets/box-sprites/beedrill.png";
import BulbasaurImg from "../../../../src/assets/box-sprites/bulbasaur.png";
import CharmanderImg from "../../../../src/assets/box-sprites/charmander.png";
import ChesnaughtImg from "../../../../src/assets/box-sprites/chesnaught.png";
import CombuskenImg from "../../../../src/assets/box-sprites/combusken.png";
import DelphoxImg from "../../../../src/assets/box-sprites/delphox.png";
import EelektrossImg from "../../../../src/assets/box-sprites/eelektross.png";
import GreninjaImg from "../../../../src/assets/box-sprites/greninja.png";
import GrotleImg from "../../../../src/assets/box-sprites/grotle.png";
import GrovyleImg from "../../../../src/assets/box-sprites/grovyle.png";
import HonedgeImg from "../../../../src/assets/box-sprites/honedge.png";
import HorseaImg from "../../../../src/assets/box-sprites/horsea.png";
import LeavannyImg from "../../../../src/assets/box-sprites/leavanny.png";
import LitwickImg from "../../../../src/assets/box-sprites/litwick.png";
import MarshtompImg from "../../../../src/assets/box-sprites/marshtomp.png";
import MonfernoImg from "../../../../src/assets/box-sprites/monferno.png";
import OnixImg from "../../../../src/assets/box-sprites/onix.png";
import PidgeotImg from "../../../../src/assets/box-sprites/pidgeot.png";
import PrinplupImg from "../../../../src/assets/box-sprites/prinplup.png";
import RaltsImg from "../../../../src/assets/box-sprites/ralts.png";
import RhyornImg from "../../../../src/assets/box-sprites/rhyorn.png";
import RotomImg from "../../../../src/assets/box-sprites/rotom.png";
import ScatterbugImg from "../../../../src/assets/box-sprites/scatterbug.png";
import SphealImg from "../../../../src/assets/box-sprites/spheal.png";
import SquirtleImg from "../../../../src/assets/box-sprites/squirtle.png";
import SwinubImg from "../../../../src/assets/box-sprites/swinub.png";
import * as React from 'react';
export default {
  AggronImg,
  AxewImg,
  BeedrillImg,
  BulbasaurImg,
  CharmanderImg,
  ChesnaughtImg,
  CombuskenImg,
  DelphoxImg,
  EelektrossImg,
  GreninjaImg,
  GrotleImg,
  GrovyleImg,
  HonedgeImg,
  HorseaImg,
  LeavannyImg,
  LitwickImg,
  MarshtompImg,
  MonfernoImg,
  OnixImg,
  PidgeotImg,
  PrinplupImg,
  RaltsImg,
  RhyornImg,
  RotomImg,
  ScatterbugImg,
  SphealImg,
  SquirtleImg,
  SwinubImg,
  React
};