import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { GuidePreview } from "../../../../src/components/guide-preview";
import { DISCORD_URL, CONTRIBUTING_URL } from "../../../../src/constants";
import CharizardImg from "../../../../src/assets/box-sprites/charizard.png";
import RotomImg from "../../../../src/assets/box-sprites/rotom.png";
import DittoImg from "../../../../src/assets/box-sprites/ditto.png";
import GiratinaImg from "../../../../src/assets/box-sprites/giratina.png";
import * as React from 'react';
export default {
  Link,
  Button,
  CheckIcon,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  GuidePreview,
  DISCORD_URL,
  CONTRIBUTING_URL,
  CharizardImg,
  RotomImg,
  DittoImg,
  GiratinaImg,
  React
};